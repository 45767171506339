<template>
  <div class="cnx-login">
    <div class="container-fluid cnx-login-container-fluid">
      <div class="row"></div>
    </div>
    <div class="col-xs-12">
      <div class="cnx-login-img">
        <!-- <img src="/media/css/apff.png" />-->
      </div>
      <div
        class="col-xs-12 col-sm-10 col-md-8 col-lg-6 offset-sm-1 offset-md-2 offset-lg-5"
        style="margin-top: 150px"
      >
        <div class="cnx-login-header">
          <h5>
            Congrès 2023 de l’APFF – du 1 au 3 novembre - Accès à la
            Webdiffusion en direct
          </h5>
        </div>

        <div class="cnx-login-card">
          <div class="cnx-login-content">
            <!-- <h4>Sera disponible sous peu</h4> -->
            <h4>Identifiez-vous pour continuer</h4>
            <div class="login">
              <div class="form-horizontal well">
                <fieldset>
                  <div class="control-group">
                    <div class="control-label">
                      <label
                        id="username-lbl"
                        for="username"
                        class="required invalid"
                      >
                        Adresse courriel
                        <span class="star">&nbsp;*</span>
                      </label>
                    </div>
                    <div class="controls">
                      <b-form-input
                        v-model="username"
                        type="text"
                        name="username"
                        id="username"
                        value
                        class="validate-username required invalid"
                        size="25"
                        required="required"
                        aria-required="true"
                        autofocus
                        aria-invalid="true"
                        autocomplete="true"
                        @keyup.enter="connecter()"
                      />
                    </div>
                  </div>

                  <div class="control-group">
                    <div class="control-label">
                      <label id="password-lbl" for="password" class="required">
                        Mot de passe
                        <span class="star">&nbsp;*</span>
                      </label>
                    </div>
                    <div class="controls">
                      <b-form-input
                        v-model="password"
                        type="password"
                        name="password"
                        id="password"
                        value
                        class="validate-password required"
                        size="25"
                        maxlength="99"
                        required="required"
                        aria-required="true"
                        autocomplete="true"
                        @keyup.enter="connecter()"
                      />
                    </div>
                  </div>
                  <div class="cnx-login-liens">
                    <span>
                      <a href="https://www.apff.org/fr/connexion/reset-password"
                        >Mot de passe oublié?</a
                      >
                    </span>
                    <br />
                  </div>
                  <div class="control-group">
                    <div class="controls">
                      <b-button @click="connecter()" :disabled="loading">
                        Se connecter
                        <b-spinner
                          small
                          v-if="loading"
                          style="
                            width: 0.75rem;
                            height: 0.75rem;
                            margin-bottom: 3px;
                          "
                        ></b-spinner>
                      </b-button>
                    </div>
                  </div>

                  <div id="cnx-system-message-container">
                    <div id="system-message-container"></div>
                  </div>

                  <b-modal
                    id="modal-demo"
                    title="Inscription"
                    @ok="onAllerDemo()"
                    cancel-title="Annuler"
                  >
                    <p class="my-4">Vous n'êtes pas inscrit à l'évènement</p>
                    <p class="my-4">
                      Souhaitez-vous accéder à la plateforme Toucan pour faire
                      des tests avant les évènements ?
                    </p>
                  </b-modal>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>-->
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      token: null,
    };
  },
  methods: {
    connecter() {
      if (this.username.length === 0 || this.password.length == 0) {
        alert("Veuillez renseigner tous les champs.");
        return;
      }
      const info = {
        nomUsager: this.username,
        motPasse: this.password,
      };

      this.loading = true;
      axios
        .post("/api/login", info)
        .then((response) => {
          console.log(response.data);
          if (response.data.résultat === "invalide") {
            alert(response.data.message);
            this.loading = false;
            return;
          }
          if (response.data.résultat === "inscriptionInvalide") {
            this.loading = false;
            this.token = response.data.token;
            this.$bvModal.show("modal-demo");
            return;
          }
          if (response.data.résultat === "ok") {
            const url =
              "https://toucan.connexence.com/#/auth?token=" +
              response.data.token;
            window.location = url;
          } else {
            console.log(response);
            alert("Désolé, une erreur est survenue");
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          alert(e);
        });
    },
    onAllerDemo() {
      const url = "https://toucan.connexence.com/#/auth?token=" + this.token;
      window.location = url;
    },
  },
};
</script>

<style>
html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

#shadow {
  margin-top: -15px;
}

iframe {
  border: 0;
}

/* #all {
	margin-top: -15px;
} */

#header,
.actions {
  display: none;
}

#banniere {
  height: 60px !important;
  background-color: #86b2d8;
  background-repeat: no-repeat;
  width: 100%;
}

.titre-webdiff {
  padding: 8px;

  background-color: #86b2d8;
  color: white;
  font-family: "Roboto", arial;
}

.titre-webdiff-principal {
  font-weight: bold;
}

.cnx-login {
  background-color: #e1f9ff;
  background: url("/media/Visuel_Toucan_2023.png") top left no-repeat;
  background-size: 100% auto;
  min-height: 894px;
  height: 100vh;
}

.cnx-login-img {
  width: 200px;
  margin: 0 auto 20px;
}

.cnx-login-img img {
  /* margin-left: 50px; */
  width: 100%;
}

.cnx-login-header {
  background-color: #e1f9ff;
  color: #22284b;
  padding: 20px 40px;
}

.cnx-login-header h5 {
  margin-bottom: 0px;
}

.cnx-login-card {
  border: 1px solid #e3e3e3;
  background-color: white;
}

.cnx-login-content {
  padding: 20px 40px;
}

.cnx-login-content h4 {
  color: #22284b;
}

.cnx-login .controls {
  width: 100%;
}

.cnx-login .controls input {
  width: 100%;

  padding: 10px 12px;
  background-color: #c9cbc9;
  border: 0;
}

.cnx-login .control-group {
  margin-bottom: 15px;
}

.cnx-login button {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 12px;
  margin-top: 15px;
  outline: 0;
  border-radius: 0;
  color: #fff;
  background: linear-gradient(to bottom, #4d858d, #2e5e65);
  border: 1px solid #adadad;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  transition-property: background;
  transition-duration: 0.5s;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
